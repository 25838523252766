import request from './request.js';

import apiS from './apiS.js';
import consult from './consult.js'; //咨询、测评
import goods from './goods.js'; //商品
import course from './course.js'; //课程
import marketing from './marketing.js'; //营销（拼团、秒杀）
import coupon from './coupon.js'; //优惠券
import combo from './combo.js'; //本地生活、套餐
import integral from './integral.js'; //积分商城
import activity from './activity.js'; //活动中心
import exam from './exam.js'; //考试管理
import textimg from './textimg.js'; //图文管理

export default {
  ...apiS, //单商户
  ...consult, //咨询、测评
  ...goods, //商品
  ...course, //课程
  ...coupon, //优惠券
  ...combo, //本地生活、套餐
  ...marketing, //营销（拼团、秒杀）
  ...integral, //积分商城
  ...activity, //活动中心
  ...exam, //考试管理
  ...textimg, //图文管理

  uploadList(_data) {
    //文件上传
    return request({
      url: '/system/upload/uploadList',
      method: 'post',
      data: _data,
    });
  },

  getToken() {
    //获取上传token
    return request({
      url: '/system/upload/getToken',
      method: 'get',
    });
  },

  articleDelete(_data) {
    //文章删除
    return request({
      url: '/system/article/articleDelete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  articleInsertOrUpdate(_data, html) {
    //文章添加或更改
    return request({
      url:
        '/system/article/articleInsertOrUpdate?data=' + JSON.stringify(_data),
      method: 'post',
      data: html,
    });
  },

  articleList(_data) {
    //文章列表
    return request({
      url: '/system/article/articleList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  aboutUs() {
    //关于我们
    return request({
      url: '/system/aboutUs/aboutUs',
      method: 'get',
    });
  },

  updateAboutUs(_data) {
    //修改关于我们
    return request({
      url: '/system/aboutUs/updateAboutUs?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  authPasswordLogin(_data) {
    //管理员登录接口
    return request({
      url: '/system/login/authPasswordLogin?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  auditDetail(_data) {
    //商户入驻审核详情
    return request({
      url: '/system/shopAudit/auditDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  initFreightTemplates(_data) {
    //初始化运费模板
    return request({
      url: '/system/freight/initFreightTemplates?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  createFreightTemplate(_data) {
    //添加运费模板
    return request({
      url:
        '/system/freight/createFreightTemplate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  editTemplateInfo(_data) {
    //（添加-删除-编辑）模板信息
    return request({
      url: '/system/freight/editTemplateInfo?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  freightTemplateDetail(_data) {
    //运费模板详情
    return request({
      url:
        '/system/freight/freightTemplateDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  removeFreightTemplate(_data) {
    //删除运费模板
    return request({
      url:
        '/system/freight/removeFreightTemplate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  setDefaultTemplate(_data) {
    //设置默认模板
    return request({
      url: '/system/freight/setDefaultTemplate?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  auditRemove(_data) {
    //审核删除
    return request({
      url: '/system/shopAudit/delete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  auditRefuse(_data) {
    //审核拒绝
    return request({
      url: '/system/shopAudit/auditRefuse?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  shopAuditList(_data) {
    //初始化入驻审核页
    return request({
      url: '/system/shopAudit/shopAuditList?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  submitSetShareBenefit(_data) {
    //提交设置分润比例
    return request({
      url:
        '/system/shopAudit/submitSetShareBenefit?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  getShopBaseInfo(_data) {
    //商户详情
    return request({
      url: '/system/shop/getShopBaseInfo?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  freezeShopAccount(_data) {
    //冻结商户账号
    return request({
      url: '/system/shop/freezeShopAccount?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  initShopListPage(_data) {
    //商户管理列表
    return request({
      url: '/system/shop/initShopListPage?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  resetPasswordForShop(_data) {
    //重置密码
    return request({
      url: '/system/shop/resetPasswordForShop?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  initAccountPage(_data) {
    //用户列表初始化
    return request({
      url: '/system/account/initAccountPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  consumerList(_data) {
    //消费账单
    return request({
      url: '/system/account/consumerList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  recommendList(_data) {
    //推荐人数
    return request({
      url: '/system/account/recommendList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  followShop(_data) {
    //关注店铺列表
    return request({
      url: '/system/account/followShop?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  freezeAndRecovery(_data) {
    //冻结|恢复
    return request({
      url: '/system/account/freezeAndRecovery?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getUserBaseInfoForShop(_data) {
    //用户详情
    return request({
      url:
        '/system/account/getUserBaseInfoForShop?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  advertiseList(_data) {
    //广告列表接口
    return request({
      url: '/system/banner/advertiseList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  advertiseUpdate(_data) {
    //广告修改接口
    return request({
      url: '/system/banner/advertiseUpdate?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  bannerList(_data) {
    //轮播图列表接口
    return request({
      url: '/system/banner/bannerList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  bannerUpdate(_data) {
    //轮播图修改接口
    return request({
      url: '/system/banner/bannerUpdate?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  bannerDelete(_data) {
    //轮播图删除接口
    return request({
      url: '/system/banner/bannerDelete?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  applyList(_data) {
    //合伙人申请列表
    return request({
      url: '/system/partner/applyList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  partnerUserList(_data) {
    //区域下的合伙人列表
    return request({
      url: '/system/partner/partnerUserList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  insertPartner(_data) {
    //添加区域
    return request({
      url: '/system/partner/insertPartner?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  insertUser(_data) {
    //添加代理人
    return request({
      url: '/system/partner/insertUser?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  partnerDetail(_data) {
    //代理商明细
    return request({
      url: '/system/partner/partnerDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  updateSystemSetup(_data) {
    //修改分佣比例
    return request({
      url: '/system/partner/updateSystemSetup?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  exportUserWithdrawListTemplate(_data) {
    //导出用户提现列表
    return request({
      url:
        '/api/finance/exportUserWithdrawListTemplate?data=' +
        JSON.stringify(_data),
      method: 'get',
      responseType: 'blob',
    });
  },

  exportFinanceListTemplate(_data) {
    //导出财务列表
    return request({
      url:
        '/api/finance/exportFinanceListTemplate?data=' + JSON.stringify(_data),
      method: 'get',
      responseType: 'blob',
    });
  },

  userWithdrawUpdate(_data) {
    //用户提现操作
    return request({
      url: '/api/finance/userWithdrawUpdate?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  userWithdrawList(_data) {
    //用户提现列表
    return request({
      url: '/api/finance/userWithdrawList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  financeList(_data) {
    //财务列表
    return request({
      url: '/api/finance/financeList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  initAdminPage(_data) {
    //管理员列表初始化
    return request({
      url: '/api/gkAdminCenter/adminList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  adminDetail(_data) {
    //管理员详情
    return request({
      url: '/api/gkAdminCenter/adminInfo?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  createAdmin(_data) {
    //添加管理员
    return request({
      url: '/api/gkAdminCenter/insert?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  editAdmin(_data) {
    //编辑管理员
    return request({
      url: '/api/gkAdminCenter/update?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  removeAdmin(_data) {
    //删除管理员
    return request({
      url: '/api/gkAdminCenter/delete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  forgetPasswordAuthIdentity(_data) {
    //忘记密码-身份验证
    return request({
      url:
        '/system/login/forgetPasswordAuthIdentity?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  forgetPasswordSubmitChange(_data) {
    //忘记密码-修改密码
    return request({
      url:
        '/system/login/forgetPasswordSubmitChange?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  roleList(_data) {
    //身份列表初始化
    return request({
      url: '/api/gkAdminRole/roleList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getAllRoles() {
    //所有身份列表
    return request({
      url: '/api/gkAdminRole/getAllRoles',
      method: 'get',
    });
  },

  createRole(_data) {
    //添加角色
    return request({
      url: '/api/gkAdminRole/createRole?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  editRole(_data) {
    //编辑角色
    return request({
      url: '/api/gkAdminRole/editRole?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  removeRole(_data) {
    //删除角色
    return request({
      url: '/api/gkAdminRole/removeRole?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  getBindingPermission(_data) {
    //获取角色已绑定权限
    return request({
      url:
        '/api/gkAdminRole/getBindingPermission?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  distributionPermissionForRole(_data) {
    //分配角色权限
    return request({
      url:
        '/api/gkAdminRole/distributionPermissionForRole?data=' +
        JSON.stringify(_data),
      method: 'post',
    });
  },

  getMenuTree() {
    //获取所有菜单树
    return request({
      url: '/api/permission/getMenuTree',
      method: 'get',
    });
  },

  getAdminWithTheMenuTree() {
    //获取用户拥有的菜单树
    return request({
      url: '/api/permission/getAdminWithTheMenuTree',
      method: 'get',
    });
  },

  initOperationLogPage(_data) {
    //操作日志列表
    return request({
      url:
        '/system/operation/initOperationLogPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  initShopShareBenefit(_data) {
    //商户分润比例设置初始化
    return request({
      url: '/system/audit/initShopShareBenefit?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  recoveryShopAccount(_data) {
    //恢复商户账号
    return request({
      url: '/system/shop/recoveryShopAccount?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getShopGoodsPage(_data) {
    //商品列表
    return request({
      url: '/system/shop/getShopGoodsPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  initShopLocalLifeOrderPage(_data) {
    //店铺线下订单列表
    return request({
      url:
        '/system/shop/initShopLocalLifeOrderPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  localLifeShopOrderDetail(_data) {
    //店铺线下订单详情
    return request({
      url:
        '/system/shop/localLifeShopOrderDetail?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getShopWithdrawalAuditPage(_data) {
    //店铺提现审核列表初始化
    return request({
      url:
        '/system/shop/getShopWithdrawalAuditPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getShopGoodsOrderPage(_data) {
    //商品订单
    return request({
      url: '/system/shop/getShopGoodsOrderPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getShopWithdrawalLPage(_data) {
    //店铺提现列表初始化
    return request({
      url: '/system/shop/getShopWithdrawalLPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getShopBalanceLogPage(_data) {
    //店铺余额列表初始化
    return request({
      url: '/system/shop/getShopBalanceLogPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getUserGoodsOrderPage(_data) {
    //商品订单
    return request({
      url:
        '/system/account/getUserGoodsOrderPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  getPlatformCategories() {
    //平台商品分类列表
    return request({
      url: '/system/goodsCategory/getPlatformCategories',
      method: 'get',
    });
  },

  addeditlocalLifeCategory(_data) {
    //添加编辑分类
    return request({
      url:
        '/system/localLifeCategory/addeditlocalLifeCategory?data=' +
        JSON.stringify(_data),
      method: 'post',
    });
  },

  deletelocalLifeCategory(_data) {
    //删除线下店铺分类
    return request({
      url:
        '/system/localLifeCategory/deletelocalLifeCategory?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  localLifeCategoryList() {
    //线下店铺分类列表
    return request({
      url: '/system/localLifeCategory/localLifeCategoryList',
      method: 'get',
    });
  },

  addLocalLifeBanner(_data) {
    //添加线下banner
    return request({
      url: '/system/banner/addLocalLifeBanner?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  getLocalLifeBannerList(_data) {
    //获取线下banner列表
    return request({
      url:
        '/system/banner/getLocalLifeBannerList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  updateLocalLifeBanner(_data) {
    //修改线下banner
    return request({
      url: '/system/banner/updateLocalLifeBanner?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  deleteLocalLifeBanner(_data) {
    //删除线下banner
    return request({
      url: '/system/banner/deleteLocalLifeBanner?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  // 系统消息 start-----------------------------------------------------------------

  findMessageList(_data) {
    // 系统消息列表
    return request({
      url: '/api/gkMssage/messageList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  choiceUserList(_data) {
    // 选择用户列表
    return request({
      url: '/system/account/initAccountPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  consultationTeacherList(_data) {
    // 选择咨询师列表
    return request({
      url:
        '/system/consultation/consultationTeacherList?data=' +
        JSON.stringify(_data),
      method: 'get',
    });
  },

  addMessage(_data, html) {
    // 发布消息
    return request({
      url: '/api/gkMssage/insert?data=' + JSON.stringify(_data),
      method: 'post',
      data: {
        content: html,
      },
    });
  },

  deleteMessage(_data) {
    // 删除系统消息
    return request({
      url: '/api/gkMssage/delete?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  // 系统消息 end-----------------------------------------------------------------

  initProvinceList(_data) {
    // 省列表初始化
    return request({
      url: '/system/region/initProvinceList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  addeditRegion(_data) {
    // 添加编辑省市区商圈
    return request({
      url: '/system/region/addeditRegion?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  removeRegion(_data) {
    // 删除省市区商圈
    return request({
      url: '/system/region/removeRegion?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  // app版本管理 start-------------------------------------------------------------------------------

  userCreate(_data) {
    // 新增用户端版本
    return request({
      url: '/system/app-version/create?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  userGetNow() {
    // 获取用户端当前最新版本
    return request({
      url: '/system/app-version/getNow',
      method: 'get',
    });
  },

  userPage(_data) {
    // 获取用户端版本列表
    return request({
      url: '/system/app-version/page?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  shopCreate(_data) {
    // 新增商铺端版本
    return request({
      url: '/system/app-version/shop-create?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  shopGetNow() {
    // 获取商铺端当前最新版本
    return request({
      url: '/system/app-version/shop-getNow',
      method: 'get',
    });
  },

  shopPage(_data) {
    // 获取商铺端版本列表
    return request({
      url: '/system/app-version/shop-page?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  shopSync(_data) {
    // 编辑商铺端版本
    return request({
      url: '/system/app-version/shop-sync?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  userSync(_data) {
    // 编辑用户端版本
    return request({
      url: '/system/app-version/sync?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  // app版本管理 end---------------------------------------------------------------------------------

  createKnowXiangMai(_data) {
    // 新增知道响买
    return request({
      url: '/system/the-text/createKnowXiangMai?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  knowXiangMaipage(_data) {
    // 知道响买分页列表
    return request({
      url: '/system/the-text/knowXiangMaipage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  updateKnowXiangMai(_data) {
    // 编辑知道响买
    return request({
      url: '/system/the-text/updateKnowXiangMai?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },

  deleteKnowXiangMai(_data) {
    // 删除知道响买{id}
    return request({
      url: '/system/the-text/deleteKnowXiangMai/' + _data,
      method: 'get',
    });
  },



  // ****************************************************************************************************
  getIndexDto() {// 获取厅管理首页
    return request({
      url: '/api/index/getIndexDto',
      method: 'get',
    });
  },
  getGameList() {// 获取游戏列表 
    return request({
      url: '/api/hall/getGameList',
      method: 'get',
    });
  },
  ZgethallList(_data) {// 获取游戏大厅列表 
    return request({
      url: '/api/hall/getList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  ZgetIndexRankDto(_data) {// 榜单 
    return request({
      url: '/api/user/getIndexRankDto?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  ZgetGoldList(_data) {// 获取充值列表-价格表 
    return request({
      url: '/api/diamonds/getGoldList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  passwordLogin(_data) {// 账号密码登录
    return request({
      url: '/api/login/authPasswordLogin?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  // 用户端****************************************************************************************************
  ZgetMyFollowMessageDto(_data) {// 我的关注列表
    return request({
      url: '/api/user/getMyFollowMessageDto?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  ZfollowUser(_data) {// 取消关注
    return request({
      url: '/api/user/followUser?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  ZblockList(_data) {// 拉黑列表
    return request({
      url: '/api/gkBlock/blockList?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  ZunBlock(_data) {// 取消拉黑
    return request({
      url: '/api/gkBlock/unBlock?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  ZsaveInviteCode(_data) {// 提交邀请码
    return request({
      url: '/api/friends/saveInviteCode?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  ZgetSpeciesInfo(_data) {// 获取金币余额
    return request({
      url: '/api/species/getSpeciesInfo?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  ZgetSpeciesLogsListDto(_data) {// 获取金币账单
    return request({
      url: '/api/species/getSpeciesLogsListDto?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  // 礼物记录****************************************************************************************************
  ZgetGiftLogListDto(_data) {// 礼物记录列表
    return request({
      url: '/api/gift/getGiftLogListDto?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  ZgetBoxLogListDto(_data) {// 开宝箱记录
    return request({
      url: '/api/gift/getBoxLogListDto?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  // 我的装扮****************************************************************************************************
  ZgetDressLogDto(_data) {// 装扮列表
    return request({
      url: '/api/user/getDressLogDto?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  ZrenewAttire(_data) {// 续费装扮
    return request({
      url: '/api/user/renewAttire?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  ZwearingAttire(_data) {// 佩戴装扮
    return request({
      url: '/api/user/wearingAttire?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  ZgetSalaryListDto(_data) {//工资周报
    return request({
      url: '/api/hall/getWeeklyDto?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  ZgetCaptainRankingWeeklyRanking(_data) {//队长周榜排行
    return request({
      url: '/api/hall/getCaptainRankingWeeklyRanking?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  ZgetUserInfo(_data) {//获取用户基本信息
    return request({
      url: '/api/user/getUserInfo?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  ZgetAuthCode(_data) {//获取验证码
    return request({
      url: '/api/login/getAuthCode?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  ZretrievePassword(_data) {//修改密码
    return request({
      url: '/api/login/retrievePassword?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  ZbindANewMobile(_data) {//更换手机号
    return request({
      url: '/api/userSet/bindANewMobile?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  ZeditUsers(_data) {//修改用户资料
    return request({
      url: '/api/user/editUsers?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  ZgetGameQueueDto(_data) {//获取队长认证的游戏类型
    return request({
      url: '/api/user/getGameQueueDto?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  ZgetGameQueueDetailDto(_data) {//获取队长认证的类型下的信息
    return request({
      url: '/api/user/getGameQueueDetailDto?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  ZapplyForCaptainReq(_data) {//队长认证
    return request({
      url: '/api/user/applyForCaptainReq?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },
  ZgetTencentToken(_data) {//获取用户id和签名
    return request({
      url: '/api/hall/getTencentToken?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  ZgetStreamDto(_data) {//获取大厅麦位和推流拉流地址
    return request({
      url: '/api/hall/getStreamDto?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  ZenterHall(_data) {//获取大厅麦位和推流拉流地址
    return request({
      url: '/api/hall/enterHall?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
  ZgetUserHallDto(_data) {//用户聊天室信息
    return request({
      url: '/api/user/getUserHallDto?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },
};
   