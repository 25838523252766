import CryptoJS from 'crypto-js/crypto-js';
const KEY = CryptoJS.enc.Utf8.parse('2523454dsfdsftyu'); // 'retedfgd' 与后台一致
const IV = CryptoJS.enc.Utf8.parse('2523454dsfdsftyu'); // 'asdqwe' 与后台一致

// 方法
export default {
    // AES加密 ：字符串 key iv  返回base64
    Encrypt:function(str, keyStr, ivStr) {
        let key = KEY;
        let iv = IV;
    
        if (keyStr && ivStr) {
            key = CryptoJS.enc.Utf8.parse(keyStr);
            iv = CryptoJS.enc.Utf8.parse(ivStr);
        }
        let srcs = CryptoJS.enc.Utf8.parse(str);
        var encrypted = CryptoJS.AES.encrypt(srcs, key, {
            iv: iv,
            // mode: CryptoJS.mode.ECB,
            mode: CryptoJS.mode.CBC,  // mode 与后台一致
            padding: CryptoJS.pad.Pkcs7,
        });
        return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    },

    // AES 解密 ：字符串 key iv  返回base64
    Decrypt:function (str, keyStr, ivStr) {
        let key = KEY;
        let iv = IV;

        if (keyStr) {
            key = CryptoJS.enc.Utf8.parse(keyStr);
            iv = CryptoJS.enc.Utf8.parse(ivStr);
        }
        
        if(str){
            let base64 = CryptoJS.enc.Base64.parse(str);
            let src = CryptoJS.enc.Base64.stringify(base64);

            var decrypt = CryptoJS.AES.decrypt(src, key, {
                iv: iv,
                // mode: CryptoJS.mode.ECB,
                mode: CryptoJS.mode.CBC, // 保持一致
                padding: CryptoJS.pad.Pkcs7
            });

            var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        }
        return  JSON.parse(decryptedStr) ;
    },

    // 加载图片
	loadImg: function (imageName){
		return require(`@/assets/imgs/${imageName}`);
	},

    splitImgStr: function(str){
        if(str){
            let arr = str.split(',');
            return arr[0]
        }else{
            return str
        }
    },

    // 隐藏手机号中间数目
    hidephone: function(num){
        if(num){
            return num.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
        }else{
            return num ;
        }
    },
    // 验证手机号
    checkMobile: function (rule, value, callback){
        // console.log(value);
        if (!value) {
            callback(new Error("请输入手机号！"));
        } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {
            callback(new Error("当前输入号码有误，请核对后再次进行输入"));
        } else {
            callback();
        }
    },
    // 验证邮箱
    checkEmail: function (rule, value, callback) {
        // console.log(value);
        const reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //正则表达式
        if (!value) {
            callback(new Error("请输入邮箱号！"));
        } else if (!reg.test(value)) {
            callback(new Error("当前输入邮箱号有误，请核对后再次进行输入"));
        } else {
            callback();
        }
    },

    // 获取本地缓存数据
    getLocal(key,json){
        let val = localStorage.getItem(key);
        if(val && json){
            return JSON.parse(val)
        }else{
            return val
        }
    },

    formateTimeStamp(timeStamp) {
        var day;
        var hour;
        var min;
        var seconds;
      
        day = parseInt(timeStamp / (24 * 60 * 60)) // 计算整数天数
        var afterDay = timeStamp - day * 24 * 60 * 60 // 取得算出天数后剩余的秒数
        hour = parseInt(afterDay / (60 * 60)) // 计算整数小时数
        var afterHour = timeStamp - day * 24 * 60 * 60 - hour * 60 * 60 // 取得算出小时数后剩余的秒数
        min = parseInt(afterHour / 60) // 计算整数分
        seconds = parseInt(timeStamp - day * 24 * 60 * 60 - hour * 60 * 60 - min * 60) // 取得算出分后剩余的秒数
      
        if (day < 10) {
          day = '0' + day;
        }
      
        if (hour < 10) {
          hour = '0' + hour
        };
      
        if (min < 10) {
          min = '0' + min;
        }
      
        if (seconds < 10) {
          seconds = '0' + seconds;
        }
      
        const restStamp = {
          day: day,
          hour: hour,
          min: min,
          seconds: seconds
        }
        return restStamp
    },
    timestampToTime(times) {//时间转换年月日时分秒
        let time = times[1]
        let mdy = times[0]
        mdy = mdy.split('/')
        let month = parseInt(mdy[0]);
        let day = parseInt(mdy[1]);
        let year = parseInt(mdy[2])
        return year + '-' + (month<10?'0'+month:month) + '-' + (day<10?'0'+day:day) + ' ' + time
    },
    timestampToTimes(times) {//时间转换年月日
        let time = times[1]
        let mdy = times[0]
        mdy = mdy.split('/')
        let month = parseInt(mdy[0]);
        let day = parseInt(mdy[1]);
        let year = parseInt(mdy[2])
        return year + '-' + (month<10?'0'+month:month) + '-' + (day<10?'0'+day:day)
    },
    nowTimestampToTime() {//当前时间  年-月-日
        let times = (new Date()).toLocaleString('en-US', { hour12: false }).split(" ")
        let time = times[1]
        let mdy = times[0]
        mdy = mdy.split('/')
        let month = parseInt(mdy[0]);
        let day = parseInt(mdy[1]);
        let year = parseInt(mdy[2])
        return year + '-' + (month<10?'0'+month:month) + '-' + (day<10?'0'+day:day)
    },
    nowTimestampToTimeM() {//当前时间  年-月
        let times = (new Date()).toLocaleString('en-US', { hour12: false }).split(" ")
        let time = times[1]
        let mdy = times[0]
        mdy = mdy.split('/')
        let month = parseInt(mdy[0]);
        let year = parseInt(mdy[2])
        return year + '-' + (month<10?'0'+month:month)
    },
    nowTimestampToTimeWeek() {//获取本周周一和周二  年-月-日
        var now = new Date();
        var nowTime = now.getTime();
        var day = now.getDay();
        console.log('day',day,nowTime)
        var oneDayTime = 24*60*60*1000;
        let arr = [
            // {title:'周0',time:this.timestampToTimess(nowTime - (day - 1) * oneDayTime)},
            {title:'周一',time:this.timestampToTimess(nowTime - (day - 1 + 0) * oneDayTime)},
            {title:'周二',time:this.timestampToTimess(nowTime - (day - 1 - 1) * oneDayTime)},
            {title:'周三',time:this.timestampToTimess(nowTime - (day - 1 - 2) * oneDayTime)},
            {title:'周四',time:this.timestampToTimess(nowTime - (day - 1 - 3) * oneDayTime)},
            {title:'周五',time:this.timestampToTimess(nowTime - (day - 1 - 4) * oneDayTime)},
            {title:'周六',time:this.timestampToTimess(nowTime - (day - 1 - 5) * oneDayTime)},
            {title:'周日',time:this.timestampToTimess(nowTime - (day - 1 - 6) * oneDayTime)}
        ]
        // timestampToTimes
        //显示周一
        var MondayTime = nowTime - (day - 1) * oneDayTime;//本周的周一
        //显示周日
        var SundayTime = nowTime + (7 - day) * oneDayTime;//本周的周日
        return {start:this.timestampToTimess(MondayTime),end:this.timestampToTimess(SundayTime),arr}
        // return {start:this.timestampToTimess(MondayTime),end:this.timestampToTimess(SundayTime)}
        // 初始化日期时间
        // var monday = new Date(MondayTime);
        // var sunday = new Date(SundayTime);
        // var time = new Date(monday);
        // var y = time.getFullYear();
        // var m = time.getMonth() + 1;
        // var d = time.getDate();
        // var start = y +'-'+m +'-'+d;
        // time = new Date(sunday);
        // y = time.getFullYear();
        // m = time.getMonth() + 1;
        // d = time.getDate();
        // var end = y +'-'+m +'-'+d;
    },
    timestampToTimess(time){//时间戳转换年月日
        var monday = new Date(time);
        var time = new Date(monday);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        return y +'-'+ (m<10?'0'+m:m) +'-'+(d<10?'0'+d:d);
    },
}