import request from './request.js'

export default {
    orderByGroupList(_data){  //拼团订单
        return request({
            url:'/system/account/orderByGroupList?data='+ JSON.stringify(_data),
            method:'get',
        })
    },

    createGroup(_data){  //添加拼团
        return request({
            url:'/system/group/createGroup?data='+ JSON.stringify(_data),
            method:'post',
        })
    },

    groupDelete(_data){  //删除拼团
        return request({
            url:'/system/group/delete?data='+ JSON.stringify(_data),
            method:'post',
        })
    },

    findGoodsPage(_data){  //查询添加商品列表
        return request({
            url:'/system/group/findGoodsPage?data='+ JSON.stringify(_data),
            method:'get',
        })
    },

    groupGoodsList(_data){  //查询列表(含搜索)
        return request({
            url:'/system/group/groupGoodsList?data='+ JSON.stringify(_data),
            method:'get',
        })
    },

    groupOrderDetail(_data){  //拼团订单详情
        return request({
            url:'/system/group/groupOrderDetail?data='+ JSON.stringify(_data),
            method:'get',
        })
    },

    groupOrderList(_data){  //拼团订单列表(含搜索)
        return request({
            url:'/system/group/groupOrderList?data='+ JSON.stringify(_data),
            method:'get',
        })
    },

    updateGroupStatus(_data){  //上下架
        return request({
            url:'/system/group/updateStatus?data='+ JSON.stringify(_data),
            method:'post',
        })
    },

    seckillGoodsPage(_data){ //查询秒杀商品
        return request({
            url:'/system/seckill/seckillGoodsList?data='+ JSON.stringify(_data),
            method:'get',
        })
    },

    createSeckill(_data){ //新增秒杀商品
        return request({
            url:'/system/seckill/createSeckill?data='+ JSON.stringify(_data),
            method:'post',
        })
    },

    removeSeckillGoods(_data){ //删除秒杀商品{idArr}
        return request({
            url:'/system/seckill/delete?data='+ JSON.stringify(_data),
            method:'post',
        })
    },

    updateSeckillStatus(_data){ //编辑秒杀商品
        return request({
            url:'/system/seckill/updateStatus?data='+ JSON.stringify(_data),
            method:'post',
        })
    },

}