import { createRouter, createWebHashHistory } from 'vue-router';

export const routes = [
  {
    path: '/login',
    name: 'login',
    meta: { notoken: true },
    component: () => import('@/data/login/login.vue'),
  },
  {
    path: '/password',
    name: 'password',
    meta: { notoken: true },
    notoken: true,
    component: () => import('@/data/login/password.vue'),
  },
  { 
    path: "/", 
    component: () => import('@/components/navbar.vue'),
    children: [
      { 
        path: "/passwords",
        name: 'passwords',
        component: () => import('@/data/passwords.vue'),
        meta:{ titL: ['修改密码'] , tab:'/information' },
      },
      { 
        path: "/passwordss", 
        name: 'passwordss',
        component: () => import('@/data/passwordss.vue'),
        meta:{ titL: ['更换手机号'] , tab:'/information' },
      },
    ]
  },
  {
    path: '/',
    title: '第一个导航页面',
    component: () => import('@/components/navbars.vue'),
    children: [
      {
        path: '/',
        name: '/',
        component: () => import('@/views_one/index/index.vue'),
        meta: { titL: ['首页'], tab: '/', keepAlive: true }, 
      },
      {
        path: '/index',
        name: '/index',
        component: () => import('@/views_one/hall/index.vue'),
        meta: { titL: ['大厅'], tab: '/', keepAlive: true },
      },
      {
        path: '/ranking',
        name: '/ranking',
        component: () => import('@/views_one/ranking/index.vue'),
        meta: { titL: ['榜单'], tab: '/', keepAlive: true },
      },
      {
        path: '/recharge',
        name: '/recharge',
        component: () => import('@/views_one/recharge/index.vue'),
        meta: { titL: ['充值'], tab: '/', keepAlive: true },
      },
      {
        path: '/room',
        name: 'room',
        component: () => import('@/views/room/index.vue'),
      },
    ],
  },
  {
    path: '/',
    title: '用户管理中心',
    component: () => import('@/components/navbar.vue'),
    children: [
      {
        path: '/regard',
        name: '/regard',
        component: () => import('@/views/regard/index.vue'),
        meta: { titL: ['我的关注'], tab: '/regard', keepAlive: true },
      },
      {
        path: '/block',
        name: '/block',
        component: () => import('@/views/block/index.vue'),
        meta: { titL: ['黑名单管理'], tab: '/block', keepAlive: true },
      },
      {
        path: '/invite',
        name: '/invite',
        component: () => import('@/views/invite/index.vue'),
        meta: { titL: ['填写邀请码'], tab: '/invite', keepAlive: true },
      },
      {
        path: '/species',
        name: '/species',
        component: () => import('@/views/species/index.vue'),
        meta: { titL: ['金币记录'], tab: '/species', keepAlive: true },
      },
      {
        path: '/gift',
        name: '/gift',
        component: () => import('@/views/gift/index.vue'),
        meta: { titL: ['礼物记录'], tab: '/gift', keepAlive: true },
      },
      {
        path: '/dress',
        name: '/dress',
        component: () => import('@/views/dress/index.vue'),
        meta: { titL: ['个性装扮'], tab: '/dress', keepAlive: true },
      },
      {
        path: '/wages',
        name: '/wages',
        component: () => import('@/views/wages/index.vue'),
        meta: { titL: ['队长值班表 '], tab: '/wages', keepAlive: true },
      },
      {
        path: '/rankings',
        name: '/rankings',
        component: () => import('@/views/Ranking/index.vue'),
        meta: { titL: ['队长排行榜 '], tab: '/rankings', keepAlive: true }, 
      },
      {
        path: '/information',
        name: '/information',
        component: () => import('@/views/set/information.vue'),
        meta: { titL: ['账号管理 '], tab: '/information', keepAlive: true }, 
      },
      {
        path: '/userInfo',
        name: '/userInfo',
        component: () => import('@/views/set/userInfo.vue'),
        meta: { titL: ['个人资料 '], tab: '/userInfo', keepAlive: true }, 
      },
      {
        path: '/queue',
        name: '/queue',
        component: () => import('@/views/set/queue.vue'),
        meta: { titL: ['队长认证 '], tab: '/queue', keepAlive: true }, 
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
